@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  @apply bg-gray-100 text-gray-900 font-sans;
}

.app-container {
  @apply min-h-screen flex flex-col;
}

main {
  @apply flex-grow;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-bold text-gray-800;
}

h1 {
  @apply text-4xl mb-6;
}

h2 {
  @apply text-3xl mb-4;
}

h3 {
  @apply text-2xl mb-3;
}

a {
  @apply text-babyblue-600 hover:text-babyblue-700 transition duration-300;
}

.btn {
  @apply inline-block px-4 py-2 rounded-full font-semibold text-white transition duration-300 transform hover:scale-105 shadow-md;
}

.btn-primary {
  @apply bg-babyblue-500 hover:bg-babyblue-600;
}

.btn-secondary {
  @apply bg-marigold-500 hover:bg-marigold-600 text-gray-900;
}

.input {
  @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-babyblue-500 focus:ring focus:ring-babyblue-500 focus:ring-opacity-50;
}

.card {
  @apply bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition duration-300;
}

/* Add any additional custom styles here */
.stretch-transition {
  transition: opacity 0.5s ease-in-out;
}

.stretch-enter {
  opacity: 0;
}

.stretch-enter-active {
  opacity: 1;
}

.stretch-exit {
  opacity: 1;
}

.stretch-exit-active {
  opacity: 0;
}